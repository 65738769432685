<template>
  <div class="NewsDetails">
    <!-- <Reserve :open="open" /> -->
    <div class="banner">
      <Head />
    </div>
    <div class="content1">
      <div class="box">
        <div class="head">
          <span style="cursor: pointer;" @click="$router.back(-1)">←</span>
          <h3>{{ data.title }}
            <div class="date">{{ data.createTime | filterDate }}</div>
          </h3>

        </div>
        <!-- <div class="text">
          {{ data.description }}
        </div> -->

        <div class="body" v-html="data.content" />
      </div>
    </div>
    <!-- <ReserveModels ref="ReserveModels" /> -->
    <Floor />
  </div>
</template>
<script>
import Reserve from '@/components/Reserve'
import Head from '@/components/Head'
import Floor from '@/components/Floor'
import ReserveModels from '@/components/ReserveModels'
import { reqGetContent } from '@/api/news'
export default {
  name: 'NewsDetails',
  components: { Reserve, Head, Floor, ReserveModels },
  filters: {
    filterDate(val) {
      if (val) {
        return val.substring(0, 10)
      }
      return val
    }
  },
  data() {
    return {
      data: {},
      videoList: []
    }
  },

  created() {
    this.getContent()
  },
  mounted() {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
  },
  methods: {
    async getContent() {
      const id = this.$route.query.id
      const res = await reqGetContent(id)
      this.data = res.data
    },
    open() {
      this.$refs['ReserveModels'].showGame = true
    }
  }

}
</script>
<style lang="scss" scoped>
::v-deep {
  .box {
    img {
      // width: 100%;
    }
      video {
        width: auto;
      }
  }
}
.NewsDetails {
  .body {
    padding-top: 20px;
  }
  .banner {
    height: 375px;
    width: 100%;
    background: url('https://thcdn.gggamedownload.com/source/assets/neiye/sucai-14.jpg') no-repeat center;
  }
  .content1 {
      padding-top: 80px;
      width: 100%;
      padding-bottom: 300px;

  .box {
    width: 935px;
    margin: 0 auto;
    color: #6C6C6C;

    .head {
      text-align: center;
      height: 75px;
      display: flex;
      justify-content: center;
      position: relative;
      border-bottom: 1px solid #ECECEC;
      .date {
        padding-top: 15px;
        font-size: 14px;
        color: #B2B2C2;
      }
      span {
        position: absolute;
        left: 0;
        top: 20px;
        width: 74px;
        height: 23px;
        border: 1px solid #B2B2C2;
        color: #B2B2C2;
        border-radius: 12px;
        font-stretch: 100px;
      }
    }

    .text {
      padding-top: 35px;
      padding-bottom: 35px;
    }
  }
  }

}
</style>
